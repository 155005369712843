#impressum{
    width: 100%;
    font-size: medium;
    background: var(--secondary-background);
    max-width: 100%;
}

.impressumItems{
    display: flex;
    justify-items: center;

}

.impressumColumn{
    margin-left: 16px;
}


#hometext{
    color:var(--primary-accent)
}

.navOptions {
    margin-right:3%;   
     margin-left:3%;
}

@media (max-width: 768px) {
    .impressumColumn{
        margin-left: 5px;
        margin-top: 10px;
    }
    .impressumItems{
        display: block;
        padding-right: 20px;
    }
    }
