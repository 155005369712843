.card{
    border-radius: 20px;
    background-color: var(--secondary-background);
    width: 45vw;
    height: 300px;
    justify-items: center;
    align-content: center;
    margin:10px;
    display: grid;
}
.cards{
    display:flex; 
    margin-bottom: 85px;
}


.bottomText {
    font-size: 0.8em;
    text-align: center;
}

@media (max-width: 768px) {
    .card{
        
        height: auto;
        width: 80vw;
    }
    .cards{
        display:grid; 
        justify-items: center;
    }
    .bottomText {
        font-size: 0.8em;
        padding-bottom: 10px;
    }
}