.section-video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%; 
    height: auto;
    min-height: 100%;
    min-width: 100%; 
    transform: translate(-50%, -50%);
    object-fit: cover;
    z-index: -1;
    filter: brightness(60%); 
  }

  .video-section {
    position: relative;
    width: 100%;
    height: 600px;
    overflow: hidden;
    -webkit-backdrop-filter: blur(22px);
    backdrop-filter: blur(22px);
  }
  

  .overlay-text {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 1;
    padding: 20px;
    width: 90%; 
  }

  .videoTitle{
    font-size: 45px;
    margin-bottom: 10vh;
    font: var(--font-primary);

}
.videoText{
font-size: large;
}

  @media (max-width: 768px) {
    .videoTitle{
        font-size: xx-large;
    }
 .videoText{
    font-size: medium;
 }

 .overlay-text {
    top: 50%;
  }
}
