.upperText{
    position: absolute;
    top: 30%;
    width: 60%;
    left: 200px;
    overflow-y: auto;
    height: max-content;
    font-size: x-large   ;
}


.entryContainer{
    position:relative; 
    width: auto;
}
.dateTime{
    position:absolute;
    left: 0px;
    width: 130px;
    top:33%;
    font-size: medium;
    font-style: italic;
}

.lowerText{

    overflow: auto;
    max-height: 120px;
    max-width: 55vw;
    position: absolute;
    top: 50%;
    left: 20vw;
    font-size: medium;
}

@media (max-width: 768px) {
    .upperText {
        top:10%; 
        height: 80px;
        left:20vw;
        font-size: large;
    }

    .lowerText{font-size: medium;}

    .dateTime{visibility: hidden;}

    .entryContainer{
        width: 90vw;
    }
}