.icon {
    width: 64px;
    height: 64px;
    padding:3px;
    margin: 2px;
    background:#000;
}

.mainBody{
      width: fit-content;
      width: 100%;
    overflow: auto;
    max-height: 360px;
    font-size: large;
    justify-items: flex-end;
    margin-top: 20px;
    padding-left: 6vw;
    padding-right: 5vw;
}

.unselectedIcon {
    width:64px;
    height: 64px;
    filter: grayscale(100%);
    padding:3px;
    margin: 2px;
    
    background:#000;
}

.languagesSection{
    width: 80%; 
    justify-self: flex-end;
    background:var(--secondary-background);

    margin-right: 3vw;
    border-radius: 25px;
    
    padding-bottom: 20px;
    margin-bottom:60px;

}

.header{
    text-decoration: underline;
}
.Iconbar{
    margin-right: 3vw;
    display: flex;
    padding-top: 10px;
    overflow-x: auto;
    justify-self: end;
    max-width: 90%;
    justify-content: end;
    justify-self: end;
}

@media (max-width: 768px) {
    .Iconbar{
        justify-content: start;

    }
    .languagesSection{ 
        width: 100%;
        }
    }
