.greetingContainer{
    margin-top: 100px;
    margin-left: 10vw;
    width: 80%;
    height: 300px;
    background-color: var(--secondary-background);
    padding: 30px;
    display: flex;
    border-radius: 5px;
}

.imageGreeting{
    width: 200px;
    height:200px;
    border-radius: 100px;
    place-self: center;

}

.bottomTextGreeting{
    font-size: large;
    overflow: auto;
    height: 80%;
}


@media (max-width: 768px) 
{
    .greetingContainer{
        width: 100%;
        margin-left: 0;

    }

    .bottomTextGreeting{
        font-size: medium;
        overflow: auto;
        height: 80%;
    }


    .imageGreeting{
        width: 100px;
        height: 100px;
    }

    
}
