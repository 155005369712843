.counter{
    font-size: 3em;
    width: 200px;
    font-weight: bold;
    height: 100px;
    text-align:center;
    align-content: center;
}

@media (max-width: 768px) {
.counter {
    font-size: 2em;
    }
}