@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --font-primary: 'Roboto Mono';
    --font-size-base: 16px;
    --font-weight-bold: 700;
    --primary-Color:#FFFFFF;
    --primary-accent:#61ffe6;
    --primary-background: #181818;
    --secondary-background: #222;
  }
  

body{
    scroll-behavior: smooth;
    color: var(--primary-Color);
    background:var(--primary-background);
    font-family: var(--font-primary);
  }

.wip{
  position: absolute;
  left: 25%;
  top: 40%;
}

:root {
  color-scheme: dark;
}